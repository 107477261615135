import { graphql, useStaticQuery } from 'gatsby'

const ContactDetails = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contactDetails {
            email
            fax
            phone
            address {
              address
              brandName
              location
              zipCode
            }
          }
        }
      }
    }
  `)

  return site.siteMetadata.contactDetails
}

export default ContactDetails
