import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import { Cards, Card, ContactCard } from '@accodeing/gatsby-theme-switsbake'
import CompanyContactDetails from 'query/contactDetails'

const massageQuery = (query) =>
  query.edges.map((edge) => {
    const node = edge.node

    return {
      name: node.name,
      title: node.title,
      phone: node.phone,
      email: node.email,
    }
  })

const Kontakt = ({ data }) => {
  const contactQuery = {
    edges: data.allGoogleSheetKontaktpersonerRow.edges.map((edge) => ({
      node: {
        name: edge.node.name,
        title: edge.node.title,
        phone: edge.node.phone,
        email: edge.node.email,
      },
    })),
  }

  const contacts = massageQuery(contactQuery)
  const contactDetails = CompanyContactDetails()

  return (
    <Layout
      pageTitle='Kontakt'
      backgroundImage={data.imageBackground.childImageSharp.gatsbyImageData}
    >
      <oma-grid-row class='row'>
        <section className='section section--with-theme-color'>
          <h1 className='page__heading page__heading--bright'>Kontakt</h1>
          <Cards className='heimr-cards--themed'>
            <Card className='card' title='Kontakt' themed={true}>
              <p>
                E-post:
                <oma-link
                  class='section__link section__link--themed'
                  to={contactDetails.email}
                />{' '}
                <br />
                Telefon:
                <oma-link
                  class='section__link section__link--themed'
                  to={contactDetails.phone}
                />{' '}
                <br />
                Fax: {contactDetails.fax}
              </p>
            </Card>
            <Card className='card' title='Adress' themed={true}>
              <p>
                {contactDetails.address.brandName} <br />
                {contactDetails.address.address} <br />
                {contactDetails.address.zipCode}{' '}
                {contactDetails.address.location}
              </p>
            </Card>
            <Card className='card' title='Besöksadress' themed={true}>
              <p>
                {contactDetails.address.brandName} <br />
                {contactDetails.address.address} <br />
                {contactDetails.address.zipCode}{' '}
                {contactDetails.address.location}
              </p>
            </Card>
          </Cards>
        </section>
        <section className='section'>
          <Cards>
            {contacts.map((contact, index) => (
              <ContactCard
                name={contact.name}
                jobTitle={contact.title}
                phone={contact.phone}
                email={contact.email}
                key={index}
              />
            ))}
          </Cards>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export const query = graphql`
  {
    imageBackground: file(relativePath: { eq: "coffee-cookies.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
      }
    }
    allGoogleSheetKontaktpersonerRow {
      edges {
        node {
          name
          title
          phone
          email
        }
      }
    }
  }
`

export default Kontakt
